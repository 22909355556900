import { ClientOptions, Execution, FluentExecution, OperationInput } from '@garner-health/api-client-core';
import type { Headers } from '@garner-health/api-client-core';
export type GetRecommendationsRequest = {
  headers?: Headers;
  query: {
    account_id: number;
    lat: number;
    lng: number;
    location_title?: string;
    care_goal: string;
    type: 'SPECIALTY' | 'PROCEDURE' | 'SYMPTOM' | 'CONDITION' | 'PROVIDER';
    triage_resolution?: 'URGENT_CARE_CLINIC' | 'PRIMARY_CARE_PHYSICIAN';
    gender?: 'MALE' | 'FEMALE';
    search_type?: 'CLIENT_PREFERRED' | 'GARNER_PREFERRED';
    allow_incomplete_directory?: boolean;
    location_type?: ('PHYSICAL' | 'VIRTUAL')[];
  };
};
export type GetRecommendationsResponses = {
  302: {
    status: 302;
    url: string;
    headers: Headers;
    json: {
      recommendation_id: number;
      url: string;
      [k: string]: unknown;
    };
  };
  422: {
    status: 422;
    url: string;
    headers: Headers;
    json: {
      request_id?: number;
      message: string;
      error?: string;
      data: {
        care_goal: string;
        type: string;
        [k: string]: unknown;
      };
      [k: string]: unknown;
    };
  };
};
export type CreateRecommendationRequest = {
  headers?: Headers;
  json: {
    account_id: number;
    source:
      | 'CONCIERGE_WEB'
      | 'CONCIERGE_API'
      | 'CONCIERGE_VOICE'
      | 'CONCIERGE_EMAIL'
      | 'CLAIMS_INTERNAL'
      | 'CLAIMS_MEMBER_OUTREACH';
    providers: {
      provider_id: string;
      specialty?: string;
      first_name?: string;
      last_name?: string;
      npi: string;
      facility_name?: string;
      cost_quality_score?: number;
      type?: 'physician' | 'facility';
      approval_date?: string;
      backdate_reason?:
        | 'MEMBER_REQUESTED_PROVIDER_ON_EARLIER_DAY'
        | 'PROVIDER_ASSOCIATED_WITH_PREVIOUSLY_APPROVED_PROVIDER'
        | 'OTHER';
    }[];
    [k: string]: unknown;
  } & (
    | {
        category: 'TOP_PROVIDER';
        reason: 'CONCIERGE_R12N' | 'MEMBER_REQUEST_NEW' | 'MEMBER_REQUEST_EXISTING' | 'MEMBER_REQUEST_UNKNOWN';
        [k: string]: unknown;
      }
    | {
        category: 'AUTO_APPROVAL_SPECIALTY';
        reason:
          | 'CONCIERGE_R12N'
          | 'MEMBER_REQUEST_NEW'
          | 'MEMBER_REQUEST_EXISTING'
          | 'MEMBER_REQUEST_UNKNOWN'
          | 'LEVEL_MID'
          | 'FACILITIES';
        [k: string]: unknown;
      }
    | {
        category: 'CONTINUITY_OF_CARE';
        reason:
          | 'PREGNANCY_RELATED'
          | 'CARE_FOR_CHILD'
          | 'TERMINAL_ILLNESS'
          | 'ONGOING_TREATMENT'
          | 'SERIOUS_CHRONIC_CONDITION'
          | 'ALREADY_PLANNED_PROCEDURE'
          | 'PSYCHOTHERAPY'
          | 'PHYSICAL_THERAPY'
          | 'MEDICATION'
          | 'FOLLOW_UP_SERIOUS_PROCEDURE'
          | 'FOLLOW_UP_EMERGENCY'
          | 'DIAGNOSING_COMPLEX_CONDITION';
        [k: string]: unknown;
      }
    | {
        category: 'PCP_ER_COVERAGE';
        reason: 'EXISTING_PCP' | 'URGENT_CARE';
        [k: string]: unknown;
      }
    | {
        category: 'AFFILIATED_PHYSICIAN';
        [k: string]: unknown;
      }
    | {
        category: 'EXCEPTION_OTHER';
        reason:
          | 'CLIENT_REQUEST'
          | 'APPROVED_EXPANDED_SEARCH'
          | 'TIME_SENSITIVE'
          | 'VIP_REQUEST'
          | 'EXCEPTION_DATA'
          | 'EXCEPTION_OTHER';
        [k: string]: unknown;
      }
  );
};
export type CreateRecommendationResponses = {
  201: {
    status: 201;
    url: string;
    headers: Headers;
    json: {
      id: number;
      account_id: number;
      created_at: string;
    };
  };
};
export type GetRecommendationRequest = {
  params: {
    id: number;
  };
  headers?: Headers;
  query: {
    account_id: number;
  };
};
export type GetRecommendationResponses = {
  200: {
    status: 200;
    url: string;
    headers: Headers;
    json: {
      recommendation: {
        id: number;
        created_at: string;
        request_position?: {
          lat: number;
          lng: number;
          [k: string]: unknown;
        };
        request_position_title?: string;
        care_goal_type?: 'SPECIALTY' | 'PROCEDURE' | 'SYMPTOM' | 'CONDITION' | 'PROVIDER';
        care_goal?: string;
        triage_resolution?: 'URGENT_CARE_CLINIC' | 'PRIMARY_CARE_PHYSICIAN';
        account_id: number;
        care_goal_id?: string;
        care_goal_metric_ids?: string[];
        related_care_goals?: {
          id: string;
          metric_ids: string[];
          type: 'SPECIALTY' | 'PROCEDURE' | 'SYMPTOM' | 'CONDITION';
          [k: string]: unknown;
        }[];
        search_type?: 'CLIENT_PREFERRED' | 'GARNER_PREFERRED';
        location_type?: ('PHYSICAL' | 'VIRTUAL')[];
        gender?: 'MALE' | 'FEMALE';
        providers: {
          id: string;
          specialty: string;
          metrics: {
            id: string;
            value: string;
            [k: string]: unknown;
          }[];
          phone_number?: string;
          location: {
            lines?: string[];
            city?: string;
            state?: string;
            zip_code?: string;
            position?: {
              lat: number;
              lng: number;
              [k: string]: unknown;
            };
            location_type: 'PHYSICAL' | 'VIRTUAL';
            booking_link?: string;
            website?: string;
            [k: string]: unknown;
          };
          hours: {
            day: 1 | 2 | 3 | 4 | 5 | 6 | 7;
            open: number;
            close: number;
            [k: string]: unknown;
          }[];
          distance_mi?: number;
          type: 'PHYSICIAN' | 'FACILITY';
          first_name?: string;
          last_name?: string;
          languages?: string[];
          overall_score?: number;
          review_stars?: number;
          gender?: 'MALE' | 'FEMALE';
          credentials?: string;
          is_top_provider?: boolean;
          availability_within_weeks?: number;
          organization_name?: string;
          accepts_new_patients?: boolean;
          is_garner_approved: boolean;
          is_pcp: boolean;
          clinical_focus?: string[];
          licensed_states?: string[];
          [k: string]: unknown;
        }[];
        [k: string]: unknown;
      };
      [k: string]: unknown;
    };
  };
  404: {
    status: 404;
    url: string;
    headers: Headers;
    json: {
      request_id?: number;
      message: string;
      error?: string;
      data: {
        account_id: number;
        recommendation_id: number;
        [k: string]: unknown;
      };
      [k: string]: unknown;
    };
  };
};
export type GetRecommendedLocationsRequest = {
  headers?: Headers;
  query: {
    account_id: number;
    offset: number;
    limit: number;
  };
};
export type GetRecommendedLocationsResponses = {
  200: {
    status: 200;
    url: string;
    headers: Headers;
    json: {
      items: {
        title: string;
        position: {
          lat: number;
          lng: number;
          [k: string]: unknown;
        };
        created_at: string;
        [k: string]: unknown;
      }[];
      offset: number;
      limit: number;
      [k: string]: unknown;
    };
  };
};
export type GetRecommendedCareGoalsRequest = {
  headers?: Headers;
  query: {
    account_id: number;
    offset: number;
    limit: number;
  };
};
export type GetRecommendedCareGoalsResponses = {
  200: {
    status: 200;
    url: string;
    headers: Headers;
    json: {
      items: (
        | {
            care_goal_type: 'specialty' | 'procedure' | 'symptom' | 'condition';
            care_goal_id: string;
            care_goal: string;
            created_at: string;
            [k: string]: unknown;
          }
        | {
            care_goal_type: 'provider';
            care_goal: string;
            created_at: string;
            [k: string]: unknown;
          }
      )[];
      offset: number;
      limit: number;
      [k: string]: unknown;
    };
  };
};
export type GetRecommendedProvidersRequest = {
  headers?: Headers;
  query: {
    account_id: number[];
    provider_id?: string;
    first_name?: string;
    last_name?: string;
    facility_name?: string;
    npi?: string;
    provider_name?: string;
    provider_type?: ('PHYSICIAN' | 'FACILITY')[];
    order_by?: {
      asc?: 'created_at';
      desc?: 'created_at';
    };
    offset?: number;
    limit?: number;
  };
};
export type GetRecommendedProvidersResponses = {
  200: {
    status: 200;
    url: string;
    headers: Headers;
    json: {
      total: number;
      offset: number;
      limit: number;
    } & {
      items: {
        id: string;
        specialty: string;
        metrics?: {
          id: string;
          value: string;
          [k: string]: unknown;
        }[];
        phone_number?: string;
        location?: {
          lines?: string[];
          city?: string;
          state?: string;
          zip_code?: string;
          position?: {
            lat: number;
            lng: number;
            [k: string]: unknown;
          };
          location_type: 'PHYSICAL' | 'VIRTUAL';
          booking_link?: string;
          website?: string;
          [k: string]: unknown;
        };
        hours?: {
          day: 1 | 2 | 3 | 4 | 5 | 6 | 7;
          open: number;
          close: number;
          [k: string]: unknown;
        }[];
        type?: 'PHYSICIAN' | 'FACILITY';
        first_name?: string;
        last_name?: string;
        languages?: string[];
        overall_score?: number;
        review_stars?: number;
        gender?: 'MALE' | 'FEMALE';
        credentials?: string;
        is_top_provider?: boolean;
        availability_within_weeks?: number;
        organization_name?: string;
        created_at: string;
        npi?: string;
        source:
          | 'APP'
          | 'CONCIERGE_WEB'
          | 'CONCIERGE_API'
          | 'CONCIERGE_VOICE'
          | 'CONCIERGE_EMAIL'
          | 'CLAIMS_INTERNAL'
          | 'CLAIMS_MEMBER_OUTREACH';
        recommendation_id: number;
        account_id: number;
        backdate_reason?:
          | 'MEMBER_REQUESTED_PROVIDER_ON_EARLIER_DAY'
          | 'PROVIDER_ASSOCIATED_WITH_PREVIOUSLY_APPROVED_PROVIDER'
          | 'OTHER';
        [k: string]: unknown;
      }[];
      [k: string]: unknown;
    };
  };
};
export type GetProvidersRequest = {
  headers?: Headers;
  query?: {
    lat?: number;
    lng?: number;
    name?: string;
    zip_code?: string;
    specialty?: string;
    procedure?: string;
    network_id?: string;
    gender?: 'MALE' | 'FEMALE';
    language?: string;
    limit?: number;
    plan?: 'CORE' | 'CORE_PLUS';
    npi?: string;
    accepts_new_patients?: 'VERIFIED_AND_UNKNOWN' | 'ALL';
    provider_search_set?: 'TOP_PROVIDER' | 'IN_NETWORK' | 'ALL';
    radius_mi?: number;
    include_low_quality_providers?: boolean;
    allow_incomplete_directory?: boolean;
    location_type?: ('PHYSICAL' | 'VIRTUAL')[];
  };
};
export type GetProvidersResponses = {
  200: {
    status: 200;
    url: string;
    headers: Headers;
    json: {
      providers: {
        id: string;
        specialty: string;
        phone_number?: string;
        location: {
          id: string;
          name?: string;
          lines?: string[];
          city?: string;
          state?: string;
          zip_code?: string;
          position?: {
            lat: number;
            lng: number;
            [k: string]: unknown;
          };
          location_type: 'PHYSICAL' | 'VIRTUAL';
          booking_link?: string;
          website?: string;
          [k: string]: unknown;
        };
        hours: {
          day: 1 | 2 | 3 | 4 | 5 | 6 | 7;
          open: number;
          close: number;
          [k: string]: unknown;
        }[];
        type: 'PHYSICIAN' | 'FACILITY';
        first_name?: string;
        last_name?: string;
        languages?: string[];
        overall_score?: number;
        review_stars?: number;
        gender?: 'MALE' | 'FEMALE';
        credentials?: string;
        is_top_provider?: boolean;
        availability_within_weeks?: number;
        organization_name?: string;
        cost_pmpm?: number;
        cost_score?: number;
        recommendation_score?: number;
        distance_score?: number;
        review_score?: number;
        reference_cost?: number;
        procedure_cost?: number;
        distance_mi?: number;
        driving_minutes?: number;
        driving_miles?: number;
        displacement_miles?: number;
        npi?: string;
        fax_number?: string;
        metadata?: {
          distance_score?: number;
          address_confidence?: number;
          address_verified_date?: string;
          accepts_new_patients_confidence?: number;
          accepts_new_patients_verified_date?: string;
          availability_within_weeks_verified_date?: string;
          phone_verified_date?: string;
          address_correctness_probability?: number;
          accepts_new_patients_probability?: number;
          [k: string]: unknown;
        };
        metrics?: {
          id: string;
          value: string;
          [k: string]: unknown;
        }[];
        accepts_new_patients?: boolean;
        network_status?: boolean;
        exclude?: boolean;
        exclusion_reasons?: string[];
        clinical_focus?: string[];
        licensed_states?: string[];
        [k: string]: unknown;
      }[];
      [k: string]: unknown;
    };
  };
  422: {
    status: 422;
    url: string;
    headers: Headers;
    json: {
      requestId?: string;
      message: string;
      error?: string;
      data?: {
        [k: string]: unknown;
      };
    };
  };
};
export type GetUrl =
  | '/recommendations'
  | '/recommendations/{id}'
  | '/recommended-locations'
  | '/recommended-care-goals'
  | '/recommended-providers'
  | '/providers';
export type PostUrl = '/recommendations';
type RequestsByMethodAndPath = {
  'GET /recommendations': GetRecommendationsRequest;
  'POST /recommendations': CreateRecommendationRequest;
  'GET /recommendations/{id}': GetRecommendationRequest;
  'GET /recommended-locations': GetRecommendedLocationsRequest;
  'GET /recommended-care-goals': GetRecommendedCareGoalsRequest;
  'GET /recommended-providers': GetRecommendedProvidersRequest;
  'GET /providers': GetProvidersRequest;
};
type ResponsesByMethodAndPath = {
  'GET /recommendations': GetRecommendationsResponses;
  'POST /recommendations': CreateRecommendationResponses;
  'GET /recommendations/{id}': GetRecommendationResponses;
  'GET /recommended-locations': GetRecommendedLocationsResponses;
  'GET /recommended-care-goals': GetRecommendedCareGoalsResponses;
  'GET /recommended-providers': GetRecommendedProvidersResponses;
  'GET /providers': GetProvidersResponses;
};
export class RecommendationServiceV2 {
  constructor(private readonly options: ClientOptions) {}
  get<Url extends GetUrl>(
    url: Url,
    input: RequestsByMethodAndPath[`GET ${Url}`],
  ): FluentExecution<ResponsesByMethodAndPath[`GET ${Url}`]>;
  get(url: string, input: OperationInput = {}): FluentExecution<Record<number, any>> {
    return new Execution(this.options, 'GET', url, input, 2);
  }
  post<Url extends PostUrl>(
    url: Url,
    input: RequestsByMethodAndPath[`POST ${Url}`],
  ): FluentExecution<ResponsesByMethodAndPath[`POST ${Url}`]>;
  post(url: string, input: OperationInput = {}): FluentExecution<Record<number, any>> {
    return new Execution(this.options, 'POST', url, input, 2);
  }
}
