import React, { useMemo } from 'react';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { useNavigate } from 'react-router-dom';
import {
  Alert,
  Box,
  Card,
  CopyToClipboardButton,
  Divider,
  formatPhoneNumber,
  KeyValueItem,
  KeyValueList,
  Stack,
  Typography,
} from '@garner-health/components-common';
import { SR, useStringResource } from '@garner-health/lib-ui-content-management';
import { analytics, Event } from '~/analytics';
import { Facility, Professional, ProfessionalLocation } from '~/clients';
import logger from '~/logging';
import { getProfessionalName } from '~/util';
import { useProviderDetails } from '~/contexts/provider-detail-context';
import { routes } from '~/router/routes';
const log = logger(__filename);

function formatProviderLocation(location: ProfessionalLocation): string[] {
  return location.lines.concat([location.city, location.state, location.zipCode].filter(Boolean).join(' '));
}

export const Address = ({ lines }: { lines: string[] }) => (
  <Box>
    {lines.map(line => (
      <Typography key={line} variant="body1">
        {line}
      </Typography>
    ))}
  </Box>
);

interface ProfessionalCardProps {
  professional: Professional;
  facility?: never;
  location: ProfessionalLocation;
  showAlert?: boolean;
}
interface FacilityCardProps {
  professional?: never;
  facility: Facility;
  location: ProfessionalLocation;
  showAlert?: never;
}
type CardContentProps = ProfessionalCardProps | FacilityCardProps;

const CardContent = ({ location, professional, facility, showAlert }: CardContentProps) => {
  const phoneNumberLabel = useStringResource('providerDetails', 'shareCardPhoneNumberLabel');
  const addressLabel = useStringResource('providerDetails', 'shareCardAddressLabel');
  const firstNameLabel = useStringResource('providerDetails', 'shareCardFirstNameLabel');
  const lastNameLabel = useStringResource('providerDetails', 'shareCardLastNameLabel');
  const facilityNameLabel = useStringResource('providerDetails', 'shareCardFacilityNameLabel');
  const notPreferredAlertTitle = useStringResource('providerDetails', 'notPreferredAlertTitle');
  const notPreferredAlertBody = useStringResource('providerDetails', 'notPreferredAlertBody');

  const { formattedPhoneNumber, formattedProviderLocation, formattedProviderName } = useMemo(
    () => ({
      formattedPhoneNumber: location.phoneNumber ? formatPhoneNumber(location.phoneNumber) : undefined,
      formattedProviderLocation: formatProviderLocation(location),
      formattedProviderName: professional ? getProfessionalName(professional) : facility.organizationName,
    }),
    [location, professional, facility],
  );

  const copyFullInfo = useMemo(() => {
    return [formattedProviderName, formattedPhoneNumber, ...formattedProviderLocation].filter(Boolean).join('\n');
  }, [formattedProviderName, formattedPhoneNumber, formattedProviderLocation]);

  const items: KeyValueItem[] = [
    {
      key: addressLabel,
      value: formattedProviderLocation,
      allowCopy: true,
      onCopy: () => {
        analytics.track(Event.COPY_FIELD_TO_CLIPBOARD, { field: 'address' });
      },
    },
  ];

  if (formattedPhoneNumber) {
    items.unshift({
      key: phoneNumberLabel,
      value: formattedPhoneNumber,
      allowCopy: true,
      onCopy: () => {
        analytics.track(Event.COPY_FIELD_TO_CLIPBOARD, { field: 'phone number' });
      },
    });
  }

  if (professional) {
    items.unshift(
      {
        key: firstNameLabel,
        value: professional.firstName,
        allowCopy: true,
        onCopy: () => {
          analytics.track(Event.COPY_FIELD_TO_CLIPBOARD, { field: 'first name' });
        },
      },
      {
        key: lastNameLabel,
        value: professional.lastName,
        allowCopy: true,
        onCopy: () => {
          analytics.track(Event.COPY_FIELD_TO_CLIPBOARD, { field: 'last name' });
        },
      },
    );
  } else {
    items.unshift({
      key: facilityNameLabel,
      value: facility.organizationName,
      allowCopy: true,
      onCopy: () => {
        analytics.track(Event.COPY_FIELD_TO_CLIPBOARD, { field: 'facility name' });
      },
    });
  }

  return (
    <Stack>
      <Typography variant="h3">
        <SR package="providerDetails" id="shareCardLabel" />
      </Typography>

      {showAlert && <Alert severity="warning" title={notPreferredAlertTitle} body={notPreferredAlertBody} />}

      <KeyValueList items={items} />

      <Divider />

      <Box variant="dotted" padding="xs">
        <Typography variant="subtitle2">{formattedProviderName}</Typography>
        {formattedPhoneNumber && <Typography variant="body1">{formattedPhoneNumber}</Typography>}
        <Address lines={formattedProviderLocation} />
      </Box>

      <CopyToClipboardButton
        icon={<ContentCopyOutlinedIcon />}
        iconPosition="start"
        value={copyFullInfo}
        onClick={() => {
          analytics.track(Event.COPY_CARD_TO_CLIPBOARD);
        }}
      >
        <SR package="providerDetails" id="copyToClipboardButtonText" />
      </CopyToClipboardButton>
    </Stack>
  );
};

export const ShareProviderCard = () => {
  const navigate = useNavigate();
  const details = useProviderDetails();
  if (!details) return;
  const { professional, facility, locationId, specialty, networkId, showQualityIndicators } = details;

  const location = professional ? professional.locations.get(locationId) : facility.location;
  if (!location) {
    log.error({ providerId: professional?.id ?? facility?.id, locationId }, 'location not found in provider');
    navigate(routes.notFound());
    return;
  }

  const showAlert =
    showQualityIndicators &&
    professional &&
    !professional.specialties
      .get(specialty)
      ?.locations.find(l => l.locationId === locationId)
      ?.networks.find(n => n.networkId === networkId)?.isTopProvider;

  return (
    <Stack spacing="xs">
      {professional ? (
        <Card content={<CardContent professional={professional} location={location} showAlert={showAlert} />} />
      ) : (
        <Card content={<CardContent facility={facility} location={location} />} />
      )}
    </Stack>
  );
};
