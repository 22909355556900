import React, { useCallback, useEffect } from 'react';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import HubOutlinedIcon from '@mui/icons-material/HubOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import MedicalServicesOutlinedIcon from '@mui/icons-material/MedicalServicesOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { FieldValues, useForm } from 'react-hook-form';
import {
  AutocompleteInput,
  Button,
  Form,
  Grid,
  InputGroup,
  SelectInput,
  SelectOption,
  Stack,
  TextInput,
} from '@garner-health/components-common';
import { SR, useStringResource } from '@garner-health/lib-ui-content-management';
import { analytics, Event } from '~/analytics';
import { Network } from '~/clients';
import { useNetworks } from '~/contexts';
import { CareGoalSelectOption, geocodeLocation, getCareGoals, LocationSelectOption } from './common';
import { Gender, languageOptions, useGenderOptions } from './select-options';

type NetworkSelectOption = SelectOption & {
  isHcscNetwork: boolean;
};

export interface SearchFormData {
  careGoal: CareGoalSelectOption | null;
  providerName: string | null;
  location: LocationSelectOption;
  network: NetworkSelectOption;
  language: string | null;
  gender: Gender | '' | null;
}

export type UnvalidatedSearchFormData = { [K in keyof SearchFormData]: SearchFormData[K] | null };

interface SearchFormProps {
  initialValues: SearchFormData | null;
  isDisabled: boolean;
  onSubmit: (data: SearchFormData) => void;
  shouldReset: boolean;
}

async function fetchNetworkData(networks: Network[], input: string): Promise<NetworkSelectOption[]> {
  if (!input) {
    return networks.map(network => ({ label: network.name, value: network.id, isHcscNetwork: network.isHcscNetwork }));
  }
  return networks
    .filter(i => i.name.toLowerCase().includes(input.toLowerCase()))
    .map(network => ({ label: network.name, value: network.id, isHcscNetwork: network.isHcscNetwork }));
}

function trackSearch(formData: UnvalidatedSearchFormData) {
  // Name and Care Goal
  if (formData.providerName && formData.careGoal) {
    analytics.track(Event.CARE_GOAL_AND_NAME_SEARCH, {
      careGoal: formData.careGoal.label,
      careGoalType: formData.careGoal.type,
      providerName: formData.providerName,
    });
  }
  // Name only
  if (formData.providerName) {
    analytics.track(Event.NAME_SEARCH, { providerName: formData.providerName });
  }
  // Care goal only
  else {
    analytics.track(Event.CARE_GOAL_SEARCH, {
      careGoal: formData.careGoal!.label,
      careGoalType: formData.careGoal!.type,
    });
  }
  const specialty = formData.careGoal?.type !== 'not_supported' ? formData.careGoal?.specialty : null;

  if (formData.language) analytics.track(Event.FILTER_LANGUAGE, { specialty, language: formData.language });
  if (formData.gender) analytics.track(Event.FILTER_GENDER, { specialty, gender: formData.gender });
}

//TODO: improve object comparison: https://app.asana.com/0/1203374383582495/1205161221217593/f
export function hasSameValues(formData1: UnvalidatedSearchFormData, formData2: UnvalidatedSearchFormData) {
  if (formData1.network?.value !== formData2.network?.value) return false;
  if (formData1.careGoal?.value !== formData2.careGoal?.value) return false;
  if (formData1.location?.value !== formData2.location?.value) return false;
  if (formData1.location?.label !== formData2.location?.label) return false;
  if (formData1.providerName !== formData2.providerName) return false;
  if (formData1.language !== formData2.language) return false;
  if (formData1.gender !== formData2.gender) return false;
  return true;
}

/**
 * Validates that at least one of careGoal and providerName is present on the form
 */
function specialtyOrNameValidator(message: string) {
  return {
    validation: {
      validator: (_: unknown, formValues: FieldValues) => {
        return !!(formValues.careGoal || formValues.providerName?.trim());
      },
      renderMessage: () => message,
    },
  };
}

export const SearchForm = ({ initialValues, isDisabled, onSubmit, shouldReset }: SearchFormProps) => {
  const form = useForm<UnvalidatedSearchFormData>();
  const genderOptions = useGenderOptions();
  const networks = useNetworks();

  const careGoalPlaceholder = useStringResource('search', 'careGoalPlaceholderText');
  const locationPlaceholder = useStringResource('search', 'locationPlaceholderText');
  const providerNamePlaceholder = useStringResource('search', 'providerNamePlaceholderText');
  const networkPlaceholder = useStringResource('search', 'networkPlaceholderText');
  const languagePlaceholder = useStringResource('search', 'languagePlaceholderText');
  const genderPlaceholder = useStringResource('search', 'genderPlaceholderText');

  const careGoalExampleText = useStringResource('search', 'careGoalExampleText');
  const locationExampleText = useStringResource('search', 'locationExampleText');
  const networkExampleText = useStringResource('search', 'networkExampleText');

  const specialtyOrNameValidationText = useStringResource('search', 'specialtyOrNameValidationText');
  const isNarrowView = innerWidth < 900;

  useEffect(() => {
    if (!initialValues) return;
    // the form could already have the initial values because the form remounts when it submits and updates the url params
    if (hasSameValues(form.getValues(), initialValues)) return;
    form.reset(initialValues);
  }, []);

  useEffect(() => {
    if (!shouldReset) return;
    form.reset({
      careGoal: null,
      providerName: null,
      location: null,
      network: null,
      language: null,
      gender: null,
    });
  }, [shouldReset]);

  function _onSubmit(formData: UnvalidatedSearchFormData) {
    trackSearch(formData);
    onSubmit({
      ...formData,
      providerName: formData.providerName?.trim() === '' ? null : formData.providerName?.trim() ?? null,
      gender: formData.gender === '' ? null : formData.gender,
      language: formData.language === '' ? null : formData.language,
      location: formData.location!,
      network: formData.network!,
    });
  }

  const fetchCareGoals = useCallback((query: string) => getCareGoals({ query }), []);

  return (
    <Form form={form} onSubmit={_onSubmit}>
      <Stack>
        <InputGroup>
          <InputGroup.Item>
            <AutocompleteInput
              aria-label={careGoalPlaceholder}
              name="careGoal"
              fetchOptions={fetchCareGoals}
              inputIcon={<MedicalServicesOutlinedIcon />}
              optionIcon={<MedicalServicesOutlinedIcon />}
              label={careGoalPlaceholder}
              exampleText={careGoalExampleText}
              //TODO: improve custom validation across multiple inputs. https://app.asana.com/0/1203374383582495/1205127243912402/f
              customValidation={specialtyOrNameValidator(isNarrowView ? '' : specialtyOrNameValidationText)}
              debounceWait={200}
            />
          </InputGroup.Item>
          <InputGroup.Item>
            <TextInput
              name="providerName"
              label={providerNamePlaceholder}
              fullWidth
              startIcon={<AccountCircleOutlinedIcon />}
              customValidation={specialtyOrNameValidator(isNarrowView ? specialtyOrNameValidationText : '')}
              allowClear
            />
          </InputGroup.Item>

          <InputGroup.Item>
            <AutocompleteInput
              aria-label={locationPlaceholder}
              name="location"
              label={locationPlaceholder}
              exampleText={locationExampleText}
              fetchOptions={geocodeLocation}
              inputIcon={<LocationOnOutlinedIcon />}
              optionIcon={<LocationOnOutlinedIcon />}
              validation={{ required: true }}
              debounceWait={200}
            />
          </InputGroup.Item>

          <InputGroup.Item>
            <AutocompleteInput
              aria-label={networkPlaceholder}
              name="network"
              label={networkPlaceholder}
              exampleText={networkExampleText}
              fetchOptions={input => fetchNetworkData(networks, input)}
              inputIcon={<HubOutlinedIcon />}
              optionIcon={<HubOutlinedIcon />}
              debounceWait={0}
              validation={{ required: true }}
            />
          </InputGroup.Item>
        </InputGroup>

        <Grid spacing="sm">
          <Grid.Item xs={12} sm={4} lg={2}>
            <SelectInput
              name="language"
              label={languagePlaceholder}
              size="small"
              iconColor="common.muted"
              adornment={<LocationOnOutlinedIcon />}
              options={languageOptions}
            />
          </Grid.Item>
          <Grid.Item xs={12} sm={4} lg={2}>
            <SelectInput
              name="gender"
              label={genderPlaceholder}
              size="small"
              iconColor="common.muted"
              adornment={<PersonOutlineOutlinedIcon />}
              options={genderOptions}
            />
          </Grid.Item>
          <Grid.Item xs={12} sm={4} lg={8} textAlign="right">
            <Button
              type="submit"
              variant="primary"
              size="small"
              paddingX="xl"
              icon={<SearchOutlinedIcon />}
              disabled={isDisabled}
            >
              <SR package="search" id="submitSearchButtonText" />
            </Button>
          </Grid.Item>
        </Grid>
      </Stack>
    </Form>
  );
};
