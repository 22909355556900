import { ClientOptions, Execution, FluentExecution, OperationInput } from '@garner-health/api-client-core';
import type { Headers } from '@garner-health/api-client-core';
export type GetGeocodeSuggestionsRequest = {
  headers?: Headers;
  query: {
    query: string;
  };
};
export type GetGeocodeSuggestionsResponses = {
  200: {
    status: 200;
    url: string;
    headers: Headers;
    json: {
      items: {
        formattedAddress: string;
        position: {
          lat: number;
          lng: number;
        };
        locationType: 'place' | 'fullAddress';
      }[];
    };
  };
};
export type GetGeolocationsRequest = {
  headers?: Headers;
  query?: {
    lat?: number;
    lng?: number;
    query?: string;
  };
};
export type GetGeolocationsResponses = {
  200: {
    status: 200;
    url: string;
    headers: Headers;
    json: {
      items: {
        formattedAddress: string;
        position: {
          lat: number;
          lng: number;
        };
        locationType: 'place' | 'fullAddress';
        stateCode?: string;
        [k: string]: unknown;
      }[];
      [k: string]: unknown;
    };
  };
  422: {
    status: 422;
    url: string;
    headers: Headers;
    json: {
      requestId?: string;
      message: string;
      error?: string;
      data?: {
        [k: string]: unknown;
      };
    };
  };
};
export type GetUrl = '/geocode' | '/geolocations';
type RequestsByMethodAndPath = {
  'GET /geocode': GetGeocodeSuggestionsRequest;
  'GET /geolocations': GetGeolocationsRequest;
};
type ResponsesByMethodAndPath = {
  'GET /geocode': GetGeocodeSuggestionsResponses;
  'GET /geolocations': GetGeolocationsResponses;
};
export class GeocodeServiceV1 {
  constructor(private readonly options: ClientOptions) {}
  get<Url extends GetUrl>(
    url: Url,
    input: RequestsByMethodAndPath[`GET ${Url}`],
  ): FluentExecution<ResponsesByMethodAndPath[`GET ${Url}`]>;
  get(url: string, input: OperationInput = {}): FluentExecution<Record<number, any>> {
    return new Execution(this.options, 'GET', url, input, 1);
  }
}
