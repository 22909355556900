import { ClientOptions, Execution, FluentExecution, OperationInput } from '@garner-health/api-client-core';
import type { Headers } from '@garner-health/api-client-core';
export type LookupRecommendationsRequest = {
  headers?: Headers;
  query?: {
    id?: number;
    providerId?: string;
    accountId?: number;
  };
};
export type LookupRecommendationsResponses = {
  200: {
    status: 200;
    url: string;
    headers: Headers;
    json: {
      recommendations: (({
        createdAt?: string;
        requestPosition?: {
          lat: number;
          lng: number;
        };
        requestPositionTitle?: string;
        careGoalType?: 'specialty' | 'procedure' | 'symptom' | 'condition' | 'provider';
        careGoal?: string;
        triageResolution?: 'urgent_care_clinic' | 'primary_care_physician';
      } & {
        id: number;
        accountId: number;
        careGoalId?: string;
        careGoalMetricIds?: string[];
        relatedCareGoals?: {
          id: string;
          metricIds: string[];
          type: 'specialty' | 'procedure' | 'symptom' | 'condition' | 'provider';
        }[];
      }) & {
        areaProviderCount?: number;
        providers: {
          id: string;
          specialty?: string;
          metrics?: {
            id: string;
            value: string;
          }[];
          phoneNumber?: string;
          location?: {
            lines: string[];
            city: string;
            state: string;
            zipCode: string;
            position: {
              lat: number;
              lng: number;
            };
          };
          hours?: {
            day: 1 | 2 | 3 | 4 | 5 | 6 | 7;
            open: number;
            close: number;
          }[];
          distanceMi?: number;
          type?: 'physician' | 'facility';
          firstName?: string;
          lastName?: string;
          languages?: string[];
          overallScore?: number;
          reviewStars?: number;
          gender?: 'male' | 'female';
          credentials?: string;
          isTopProvider?: boolean;
          availabilityWithinWeeks?: number;
          organizationName?: string;
        }[];
      })[];
    };
  };
};
export type GetRecommendationsRequest = {
  params: {
    accountId: number;
  };
  headers?: Headers;
  query: {
    query?: string;
    language?: 'en-US' | 'es-US';
    lat?: number;
    lng?: number;
    locationTitle?: string;
    careGoal?: string;
    type?: 'specialty' | 'procedure' | 'symptom' | 'condition' | 'provider';
    triageResolution?: 'urgent_care_clinic' | 'primary_care_physician';
    gender?: 'male' | 'female';
    searchType?: 'client_preferred' | 'garner_preferred';
    allowIncompleteDirectory: boolean;
  };
};
export type GetRecommendationsResponses = {
  200: {
    status: 200;
    url: string;
    headers: Headers;
    json:
      | {
          hasResults: boolean;
          recommendationId: number | '-';
          url: string;
        }
      | {
          suggestions: {
            term: string;
            type: 'specialty' | 'procedure' | 'symptom' | 'condition' | 'provider';
            careGoal: string;
            careGoalId: string;
            suggestPrimaryCare?: boolean;
            suggestConcierge?: boolean;
            suggestConciergeOnly?: boolean;
            promoteConcierge?: boolean;
          }[];
        };
  };
  302: {
    status: 302;
    url: string;
    headers: Headers;
    json: {
      hasResults: true;
      recommendationId: number | '-';
      url: string;
    };
  };
  422: {
    status: 422;
    url: string;
    headers: Headers;
    json: {
      requestId?: number;
      message: string;
      error?: string;
      data: {
        careGoal: string;
        type: string;
      };
    };
  };
};
export type CreateRecommendationRequest = {
  params: {
    accountId: number;
  };
  headers?: Headers;
  json: {
    createdAt?: string;
    requestPosition?: {
      lat: number;
      lng: number;
    };
    requestPositionTitle?: string;
    careGoalType?: 'specialty' | 'procedure' | 'symptom' | 'condition' | 'provider';
    careGoal?: string;
    triageResolution?: 'urgent_care_clinic' | 'primary_care_physician';
    source:
      | 'app'
      | 'concierge_web'
      | 'concierge_api'
      | 'concierge_voice'
      | 'concierge_email'
      | 'claims_internal'
      | 'claims_member_outreach';
    providers: {
      providerId: string;
      specialty?: string;
      locationId?: string;
      firstName?: string;
      lastName?: string;
      npi?: string;
      facilityName?: string;
      type?: 'physician' | 'facility';
      costQualityScore?: number;
    }[];
    areaProviderCount?: number;
  };
};
export type CreateRecommendationResponses = {
  200: {
    status: 200;
    url: string;
    headers: Headers;
    json: {
      createdAt?: string;
      requestPosition?: {
        lat: number;
        lng: number;
      };
      requestPositionTitle?: string;
      careGoalType?: 'specialty' | 'procedure' | 'symptom' | 'condition' | 'provider';
      careGoal?: string;
      triageResolution?: 'urgent_care_clinic' | 'primary_care_physician';
    } & {
      id: number;
      accountId: number;
      careGoalId?: string;
      careGoalMetricIds?: string[];
      relatedCareGoals?: {
        id: string;
        metricIds: string[];
        type: 'specialty' | 'procedure' | 'symptom' | 'condition' | 'provider';
      }[];
    };
  };
};
export type GetEmptyRecommendationRequest = {
  params: {
    accountId: number;
  };
  headers?: Headers;
};
export type GetEmptyRecommendationResponses = {
  200: {
    status: 200;
    url: string;
    headers: Headers;
    json: {
      recommendation: ({
        createdAt?: string;
        requestPosition?: {
          lat: number;
          lng: number;
        };
        requestPositionTitle?: string;
        careGoalType?: 'specialty' | 'procedure' | 'symptom' | 'condition' | 'provider';
        careGoal?: string;
        triageResolution?: 'urgent_care_clinic' | 'primary_care_physician';
      } & {
        id: number;
        accountId: number;
        careGoalId?: string;
        careGoalMetricIds?: string[];
        relatedCareGoals?: {
          id: string;
          metricIds: string[];
          type: 'specialty' | 'procedure' | 'symptom' | 'condition' | 'provider';
        }[];
      }) & {
        areaProviderCount?: number;
        searchType?: 'client_preferred' | 'garner_preferred';
        providers: {
          id: string;
          specialty: string;
          metrics: {
            id: string;
            value: string;
          }[];
          phoneNumber?: string;
          location: {
            lines: string[];
            city: string;
            state: string;
            zipCode: string;
            position: {
              lat: number;
              lng: number;
            };
          };
          hours: {
            day: 1 | 2 | 3 | 4 | 5 | 6 | 7;
            open: number;
            close: number;
          }[];
          distanceMi: number;
          type: 'physician' | 'facility';
          firstName?: string;
          lastName?: string;
          languages?: string[];
          overallScore?: number;
          reviewStars?: number;
          gender?: 'male' | 'female';
          credentials?: string;
          isTopProvider?: boolean;
          availabilityWithinWeeks?: number;
          organizationName?: string;
          badges: {
            id?: string;
            [k: string]: unknown;
          }[];
          acceptsNewPatients?: boolean;
          isGarnerApproved: boolean;
          isPcp: boolean;
        }[];
      };
    };
  };
};
export type GetRecommendationRequest = {
  params: {
    accountId: number;
    recommendationId: number;
  };
  headers?: Headers;
};
export type GetRecommendationResponses = {
  200: {
    status: 200;
    url: string;
    headers: Headers;
    json: {
      recommendation: ({
        createdAt?: string;
        requestPosition?: {
          lat: number;
          lng: number;
        };
        requestPositionTitle?: string;
        careGoalType?: 'specialty' | 'procedure' | 'symptom' | 'condition' | 'provider';
        careGoal?: string;
        triageResolution?: 'urgent_care_clinic' | 'primary_care_physician';
      } & {
        id: number;
        accountId: number;
        careGoalId?: string;
        careGoalMetricIds?: string[];
        relatedCareGoals?: {
          id: string;
          metricIds: string[];
          type: 'specialty' | 'procedure' | 'symptom' | 'condition' | 'provider';
        }[];
      }) & {
        areaProviderCount?: number;
        searchType?: 'client_preferred' | 'garner_preferred';
        providers: {
          id: string;
          specialty: string;
          metrics: {
            id: string;
            value: string;
          }[];
          phoneNumber?: string;
          location: {
            lines: string[];
            city: string;
            state: string;
            zipCode: string;
            position: {
              lat: number;
              lng: number;
            };
          };
          hours: {
            day: 1 | 2 | 3 | 4 | 5 | 6 | 7;
            open: number;
            close: number;
          }[];
          distanceMi: number;
          type: 'physician' | 'facility';
          firstName?: string;
          lastName?: string;
          languages?: string[];
          overallScore?: number;
          reviewStars?: number;
          gender?: 'male' | 'female';
          credentials?: string;
          isTopProvider?: boolean;
          availabilityWithinWeeks?: number;
          organizationName?: string;
          badges: {
            id?: string;
            [k: string]: unknown;
          }[];
          acceptsNewPatients?: boolean;
          isGarnerApproved: boolean;
          isPcp: boolean;
        }[];
      };
    };
  };
  404: {
    status: 404;
    url: string;
    headers: Headers;
    json: {
      requestId?: number;
      message: string;
      error?: string;
      data: {
        accountId: number;
        recommendationId: number;
      };
    };
  };
};
export type GetProvidersRequest = {
  headers?: Headers;
  query: {
    lat?: number;
    lng?: number;
    name?: string;
    zipCode?: string;
    specialty?: string;
    procedure?: string;
    networkId?: string;
    gender?: 'male' | 'female';
    language?: string;
    limit?: number;
    plan?: 'core' | 'core_plus' | 'hcsc_compliant';
    npi?: string;
    acceptsNewPatients?: 'verifiedAndUnknown' | 'all';
    providerSearchSet?: 'top_provider' | 'in_network' | 'all';
    radiusMi?: number;
    includeLowQualityProviders?: boolean;
    allowIncompleteDirectory: boolean;
  };
};
export type GetProvidersResponses = {
  200: {
    status: 200;
    url: string;
    headers: Headers;
    json: {
      providers: {
        id: string;
        specialty: string;
        phoneNumber?: string;
        location: {
          id: string;
          name?: string;
          lines: string[];
          city: string;
          state: string;
          zipCode: string;
          position: {
            lat: number;
            lng: number;
          };
        };
        hours: {
          day: 1 | 2 | 3 | 4 | 5 | 6 | 7;
          open: number;
          close: number;
        }[];
        type: 'physician' | 'facility';
        firstName?: string;
        lastName?: string;
        languages?: string[];
        overallScore?: number;
        reviewStars?: number;
        gender?: 'male' | 'female';
        credentials?: string;
        isTopProvider?: boolean;
        availabilityWithinWeeks?: number;
        organizationName?: string;
        costPmpm?: number;
        costScore?: number;
        recommendationScore?: number;
        distanceScore?: number;
        reviewScore?: number;
        referenceCost?: number;
        procedureCost?: number;
        distanceMi?: number;
        drivingMinutes?: number;
        drivingMiles?: number;
        displacementMiles?: number;
        npi?: string;
        faxNumber?: string;
        metadata?: {
          distanceScore?: number;
          addressConfidence?: number;
          addressVerifiedDate?: string;
          acceptsNewPatientsConfidence?: number;
          acceptsNewPatientsVerifiedDate?: string;
          availabilityWithinWeeksVerifiedDate?: string;
          phoneVerifiedDate?: string;
          addressCorrectnessProbability?: number;
          acceptsNewPatientsProbability?: number;
        };
        metrics?: {
          id: string;
          value: string;
        }[];
        acceptsNewPatients?: boolean;
        networkStatus?: boolean;
        exclude?: boolean;
        exclusionReasons?: string[];
      }[];
    };
  };
  422: {
    status: 422;
    url: string;
    headers: Headers;
    json: {
      requestId?: string;
      message: string;
      error?: string;
      data?: {
        [k: string]: unknown;
      };
    };
  };
};
export type GetProvidersRecommendedForAccountRequest = {
  params: {
    accountId: number;
  };
  headers?: Headers;
  query: {
    lat: number;
    lng: number;
    specialties: string;
    name: string;
    limit?: number;
  };
};
export type GetProvidersRecommendedForAccountResponses = {
  200: {
    status: 200;
    url: string;
    headers: Headers;
    json: {
      providers: {
        id: string;
        specialty: string;
        metrics: {
          id: string;
          value: string;
        }[];
        phoneNumber?: string;
        location: {
          lines: string[];
          city: string;
          state: string;
          zipCode: string;
          position: {
            lat: number;
            lng: number;
          };
        };
        hours: {
          day: 1 | 2 | 3 | 4 | 5 | 6 | 7;
          open: number;
          close: number;
        }[];
        distanceMi: number;
        type: 'physician' | 'facility';
        firstName?: string;
        lastName?: string;
        languages?: string[];
        overallScore?: number;
        reviewStars?: number;
        gender?: 'male' | 'female';
        credentials?: string;
        isTopProvider?: boolean;
        availabilityWithinWeeks?: number;
        organizationName?: string;
        badges: {
          id?: string;
          [k: string]: unknown;
        }[];
        acceptsNewPatients?: boolean;
        isGarnerApproved?: boolean;
        isPcp?: boolean;
      }[];
    };
  };
};
export type GetRecommendedProvidersRequest = {
  headers?: Headers;
  query: {
    accountId: number | number[];
    providerId?: string;
    firstName?: string;
    lastName?: string;
    facilityName?: string;
    npi?: string;
    providerName?: string;
    providerType?: ('physician' | 'facility')[];
    limit?: number;
    offset: number;
    orderBy?: {
      asc?: 'createdAt';
      desc?: 'createdAt';
    };
  };
};
export type GetRecommendedProvidersResponses = {
  200: {
    status: 200;
    url: string;
    headers: Headers;
    json: {
      results: {
        id: string;
        specialty: string;
        metrics?: {
          id: string;
          value: string;
        }[];
        phoneNumber?: string;
        location?: {
          lines: string[];
          city: string;
          state: string;
          zipCode: string;
          position: {
            lat: number;
            lng: number;
          };
        };
        hours?: {
          day: 1 | 2 | 3 | 4 | 5 | 6 | 7;
          open: number;
          close: number;
        }[];
        type?: 'physician' | 'facility';
        firstName?: string;
        lastName?: string;
        languages?: string[];
        overallScore?: number;
        reviewStars?: number;
        gender?: 'male' | 'female';
        credentials?: string;
        isTopProvider?: boolean;
        availabilityWithinWeeks?: number;
        organizationName?: string;
        createdAt: string;
        npi?: string;
        source:
          | 'app'
          | 'concierge_web'
          | 'concierge_api'
          | 'concierge_voice'
          | 'concierge_email'
          | 'claims_internal'
          | 'claims_member_outreach';
        recommendationId: number;
        accountId: number;
      }[];
      total: number;
      offset: number;
      limit: number;
    };
  };
};
export type GetCareGoalsRequest = {
  headers?: Headers;
  query?: {
    query?: string;
    type?: ('specialty' | 'procedure' | 'symptom' | 'condition' | 'not_supported')[];
    language?: 'en-US' | 'es-US';
    id?: string[];
  };
};
export type GetCareGoalsResponses = {
  200: {
    status: 200;
    url: string;
    headers: Headers;
    json: {
      careGoals: {
        term: string;
        type: 'specialty' | 'procedure' | 'symptom' | 'condition' | 'not_supported';
        careGoal: string;
        careGoalId: string;
        specialty?: string | null;
        suggestPrimaryCare?: boolean;
        suggestConcierge?: boolean;
        promoteConcierge?: boolean;
        metricIds: string[];
        includeVirtualLocations?: boolean;
      }[];
    };
  };
};
export type GetUrl =
  | '/recommendations'
  | '/recommendations/{accountId}'
  | '/recommendations/{accountId}/-'
  | '/recommendations/{accountId}/{recommendationId}'
  | '/providers'
  | '/providers/{accountId}'
  | '/recommended-providers'
  | '/care-goals';
export type PostUrl = '/recommendations/{accountId}';
type RequestsByMethodAndPath = {
  'GET /recommendations': LookupRecommendationsRequest;
  'GET /recommendations/{accountId}': GetRecommendationsRequest;
  'POST /recommendations/{accountId}': CreateRecommendationRequest;
  'GET /recommendations/{accountId}/-': GetEmptyRecommendationRequest;
  'GET /recommendations/{accountId}/{recommendationId}': GetRecommendationRequest;
  'GET /providers': GetProvidersRequest;
  'GET /providers/{accountId}': GetProvidersRecommendedForAccountRequest;
  'GET /recommended-providers': GetRecommendedProvidersRequest;
  'GET /care-goals': GetCareGoalsRequest;
};
type ResponsesByMethodAndPath = {
  'GET /recommendations': LookupRecommendationsResponses;
  'GET /recommendations/{accountId}': GetRecommendationsResponses;
  'POST /recommendations/{accountId}': CreateRecommendationResponses;
  'GET /recommendations/{accountId}/-': GetEmptyRecommendationResponses;
  'GET /recommendations/{accountId}/{recommendationId}': GetRecommendationResponses;
  'GET /providers': GetProvidersResponses;
  'GET /providers/{accountId}': GetProvidersRecommendedForAccountResponses;
  'GET /recommended-providers': GetRecommendedProvidersResponses;
  'GET /care-goals': GetCareGoalsResponses;
};
export class RecommendationServiceV1 {
  constructor(private readonly options: ClientOptions) {}
  get<Url extends GetUrl>(
    url: Url,
    input: RequestsByMethodAndPath[`GET ${Url}`],
  ): FluentExecution<ResponsesByMethodAndPath[`GET ${Url}`]>;
  get(url: string, input: OperationInput = {}): FluentExecution<Record<number, any>> {
    return new Execution(this.options, 'GET', url, input, 1);
  }
  post<Url extends PostUrl>(
    url: Url,
    input: RequestsByMethodAndPath[`POST ${Url}`],
  ): FluentExecution<ResponsesByMethodAndPath[`POST ${Url}`]>;
  post(url: string, input: OperationInput = {}): FluentExecution<Record<number, any>> {
    return new Execution(this.options, 'POST', url, input, 1);
  }
}
