import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Form, SelectInput } from '@garner-health/components-common';
import { useStringResource } from '@garner-health/lib-ui-content-management';
import { ProvidersResponseSetType } from '~/clients';
import { SortMethod } from './sort-method';

export function useOptions(searchType: ProvidersResponseSetType): { label: string; value: SortMethod }[] {
  const options: { label: string; value: SortMethod }[] = [
    { label: useStringResource('search', 'providerSortRecommendedLabel'), value: 'recommended' },
    { label: useStringResource('search', 'providerDistanceColumnLabel'), value: 'distance' },
    { label: useStringResource('search', 'providerOverallScoreLabel'), value: 'overallScore' },
  ];

  if (searchType === 'facility') return options.slice(0, 2);

  return options;
}

export type SortByFormProps = {
  searchType: ProvidersResponseSetType;
  sortMethod: SortMethod;
  onChange: (sortMethod: SortMethod) => void;
};

export const SortByForm = ({ searchType, sortMethod, onChange }: SortByFormProps) => {
  const sortByLabel = useStringResource('search', 'sortByLabel');
  const options = useOptions(searchType);
  const form = useForm<{ sortMethod: SortMethod }>({ defaultValues: { sortMethod } });
  const formSortMethod = form.watch('sortMethod');

  useEffect(() => {
    onChange(formSortMethod);
  }, [formSortMethod]);

  if (searchType === 'hcsc_compliant') return null;
  return (
    <Form form={form}>
      <SelectInput
        label={sortByLabel}
        iconColor="common.black"
        variant="simple"
        allowClear={false}
        adornment={sortByLabel}
        name="sortMethod"
        options={options}
      />
    </Form>
  );
};
