import React, { useEffect, useReducer, useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FacilityIcon from 'jsx:~/assets/images/facility.svg';
import Physician from 'jsx:~/assets/images/physician.svg';
import { NavigateFunction, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  Box,
  FixedWidthLayout,
  IconButton,
  ProfileHeading,
  Spinner,
  TwoColumnLayout,
} from '@garner-health/components-common';
import { useStringResource } from '@garner-health/lib-ui-content-management';
import { providerClient, ProviderDetailParams, ProviderFailureReason } from '~/clients';
import { useSpecialtyLabel } from '~/contexts';
import { useGoBack } from '~/hooks';
import logger from '~/logging';
import { getProfessionalName } from '~/util';
import { ProfessionalDetails } from '~/components/provider-details';
import {
  ProviderDetailContext,
  ProviderDetailDispatchContext,
  providerDetailsReducer,
  useProviderDetails,
} from '~/contexts/provider-detail-context';
import { routes } from '~/router/routes';
import { FacilityDetails } from '~/components/provider-details/facility-details';
import { ShareProviderCard } from '~/components/provider-details/share-provider-card';

const log = logger(__filename);

function getImageSrc(origin: string, providerId: string) {
  return `${origin}/static/providers/profiles/${providerId}-70@3x.jpg`;
}

interface MainContentParams {
  metricIds: string[];
  searchParams: ProviderDetailParams;
}

const MainContent = ({ searchParams, metricIds }: MainContentParams) => {
  const specialtyLabel = useSpecialtyLabel()(searchParams.specialty);
  const providerAvatarAltText = useStringResource('providerDetails', 'providerAvatarAltText');
  const facilityText = useStringResource('providerDetails', 'facilityText');
  const professionalText = useStringResource('providerDetails', 'physicianText');
  const details = useProviderDetails();
  if (!details) return;
  const { professional, facility } = details;

  let providerName;
  let providerId;
  let fallback;

  if (professional) {
    providerName = getProfessionalName(professional);
    providerId = professional.id;
    fallback = <Physician role="img" aria-label={professionalText} />;
  } else {
    providerName = facility.organizationName;
    providerId = facility.id;
    fallback = <FacilityIcon role="img" aria-label={facilityText} />;
  }

  return (
    <>
      <ProfileHeading
        title={providerName}
        subtitle={specialtyLabel}
        avatar={{
          src: getImageSrc(location.origin, providerId),
          alt: providerAvatarAltText,
        }}
        fallback={fallback}
      />
      <Box paddingTop="xl">
        <TwoColumnLayout type="2:1">
          <TwoColumnLayout.Column1>
            {professional ? <ProfessionalDetails metricIds={metricIds} /> : <FacilityDetails />}
          </TwoColumnLayout.Column1>
          <TwoColumnLayout.Column2>
            <ShareProviderCard />
          </TwoColumnLayout.Column2>
        </TwoColumnLayout>
      </Box>
    </>
  );
};
interface FetchParams {
  providerId: string;
  searchParams: ProviderDetailParams;
  navigate: NavigateFunction;
  setLoading: (loading: boolean) => void;
}
function fetchProfessional({ providerId, searchParams, navigate, setLoading }: FetchParams) {
  return providerClient
    .getProfessional({ providerId, networkId: searchParams.networkId })
    .then(response => {
      if (!response.success) {
        handleFailureReason(response.reason, { navigate });
        return { professional: undefined, locationId: searchParams.locationId };
      } else {
        const locationId = response.data.locations.get(searchParams.locationId)
          ? searchParams.locationId
          : response.data.locations.keys().next().value;
        const currentSpecialty = response.data.specialties.get(searchParams.specialty)
          ? searchParams.specialty
          : response.data.specialties.keys().next().value;
        return { professional: response.data, locationId, currentSpecialty };
      }
    })
    .finally(() => setLoading(false));
}
function fetchFacility({ providerId, searchParams, navigate, setLoading }: FetchParams) {
  return providerClient
    .getFacility({ providerId, locationId: searchParams.locationId, networkId: searchParams.networkId })
    .then(response => {
      if (!response.success) {
        handleFailureReason(response.reason, { navigate });
        return { facility: undefined };
      } else {
        const currentSpecialty = response.data.specialties.get(searchParams.specialty)
          ? searchParams.specialty
          : response.data.specialties.keys().next().value;
        return { facility: response.data, currentSpecialty };
      }
    })
    .finally(() => {
      setLoading(false);
    });
}
function handleFailureReason(reason: ProviderFailureReason, { navigate }: { navigate: NavigateFunction }) {
  const failureReasonHandlerMap: Record<ProviderFailureReason, () => void> = {
    notFound: () => navigate(routes.notFound()),
  };
  const failureReasonHandler = failureReasonHandlerMap[reason] as (() => void) | undefined;

  if (failureReasonHandler) {
    failureReasonHandler();
  } else {
    throw new Error('Error loading provider');
  }
}

export const ProviderDetailsPage = () => {
  const [details, dispatch] = useReducer(providerDetailsReducer, {});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const goBack = useGoBack();

  const params = useParams();
  const [queryParams] = useSearchParams();

  const providerId = params.id!;
  const metricIds = queryParams.getAll('metricId');
  const specialty = queryParams.get('specialty')!;
  const locationId = queryParams.get('locationId')!;
  const networkId = queryParams.get('networkId')!;
  const searchParams = { locationId, specialty, networkId };

  useEffect(() => {
    if (!providerId || !locationId || !specialty || !networkId) {
      log.error(
        { providerId, locationId, networkId, strSpecialty: specialty, metricId: metricIds },
        'Missing required params',
      );
      navigate(routes.notFound());
      return;
    }

    setLoading(true);
    /**
     * Function to handle fetching a professional, and updating provider details context with new data
     */
    async function handleGetProfessional() {
      fetchProfessional({ providerId, searchParams, navigate, setLoading })
        .then(({ professional, locationId, currentSpecialty }) => {
          if (professional) {
            dispatch({
              type: 'updateProfessional',
              professional,
              locationId,
              specialty: currentSpecialty,
              networkId: searchParams.networkId,
            });
          }
        })
        .finally(() => setLoading(false));
    }

    /**
     * Function to handle fetching a facility, and updating provider details context with new data
     */
    async function handleGetFacility() {
      fetchFacility({ providerId, searchParams, navigate, setLoading })
        .then(({ facility, currentSpecialty }) => {
          if (facility) {
            dispatch({
              type: 'updateFacility',
              facility,
              locationId: searchParams.locationId,
              specialty: currentSpecialty,
              networkId: searchParams.networkId,
            });
          }
        })
        .finally(() => setLoading(false));
    }

    if (providerId.startsWith('p.')) {
      handleGetProfessional();
    } else {
      handleGetFacility();
    }
  }, []);

  return (
    <FixedWidthLayout maxWidth="xl">
      <Box paddingBottom="md">
        <IconButton aria-label="back" onClick={goBack}>
          <ArrowBackIcon color="action" />
        </IconButton>
      </Box>
      <ProviderDetailContext.Provider value={details}>
        <ProviderDetailDispatchContext.Provider value={dispatch}>
          {loading ? <Spinner variant="dark" /> : <MainContent metricIds={metricIds} searchParams={searchParams} />}
        </ProviderDetailDispatchContext.Provider>
      </ProviderDetailContext.Provider>
    </FixedWidthLayout>
  );
};
