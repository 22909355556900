interface ProviderPageParams {
  id: string;
  locationId: string;
  specialty: string;
  networkId: string;
  metricIds?: string[];
}

interface ResetPasswordConfirmationPageParams {
  email: string;
}

export const routes = {
  home: () => '/',
  search: () => '/search',
  account: () => '/account',
  login: () => '/sign-in',
  forgotPassword: () => '/forgot-password',
  notFound: () => '/not-found',
  providerDetails: ({ id, locationId, specialty, networkId, metricIds }: ProviderPageParams) => {
    const params = new URLSearchParams({
      locationId,
      specialty,
      networkId,
    });
    if (metricIds) {
      for (const metricId of metricIds) {
        params.append('metricId', metricId);
      }
    }
    return `/providers/${encodeURIComponent(id)}?${params.toString()}`;
  },
  resetPassword: () => '/reset-password',
  resetPasswordConfirmation: ({ email }: ResetPasswordConfirmationPageParams) =>
    `/send-reset-password-confirmation?email=${encodeURIComponent(email)}`,
};
